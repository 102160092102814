// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
$enable-shadows: true;
//$enable-gradients: true;

$input-bg: rgba(255, 255, 255, 0);

// Changing the body background and text
//$body-bg: #d3e9eb;
$body-color: #464646;

$list-group-bg: #f2f2f2;

// Changing the border radius of buttons
$btn-border-radius: 24px;
$card-border-radius: 8px;

// Changing the theme colors
$primary: #8618EA;
//$secondary: #436296;
$success: #52cc5b;
//$info: #495dff;
//$warning: #ef8143;
$danger: #ec4b4b;
$light: #ffffff;
//$dark: #0f1319;

$nav-tabs-link-active-border-color: $primary;
$nav-tabs-link-active-color: $primary;
$nav-tabs-border-width: 3px;

$btn-box-shadow: 0 6px 12px 2px rgba(0, 0, 0, 0.25);

$input-btn-padding-x: 1rem;
$input-padding-x: 0rem;
$input-placeholder-color: rgba(#464646, 0.5);
$input-box-shadow: none;
$input-border-width: 0px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;

$font-family-sans-serif: "Kanit", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$form-feedback-valid-color: $primary;

$label-margin-bottom: 0.125rem;

// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
//$theme-colors: (
//  "cool": #4d3fa3
//);
