// Add additional styles here. For example, overwrite certain styles or add new components.
// Tip: You can use bootstrap's powerful mixins here!

//.alert-myalert {
//  @include alert-variant(#60667d, #1d1d1d, #f4fdff);
//}

//@each $color, $value in $theme-colors {
//  .alert-#{$color} {
//    box-shadow: 3px 3px theme-color-level($color, -3);
//  }
//}

.btn-block {
  border-radius: 12px;
}

.btn-light.btn-block {
  color: $primary;
  &:hover {
    color: $primary;
  }
  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    color: $primary;
  }
}

.form-control {
  border-bottom: 2px solid rgba(#464646, 0.5);
  .was-validated:focus, &.is-valid:focus, &.is-invalid:focus {
    box-shadow: none;
  }
}

label {
  color: rgba(#464646, 0.5);
  font-size: 0.8rem;
}

.nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  color: lighten($secondary, 6);
}

.nav-tabs .nav-link {
  border: none;
  border-bottom: 3px solid transparent;
}

.nav-tabs .nav-link.active {
  border: none;
  border-bottom: 3px solid $primary;
}

.form-group.form-goobank {
  display: flex;
  flex-direction: column;
  position: relative;
  label {
    order: 1;
    width: 50%;
  }
  .form-control {
    order: 2;
    .was-validated &:valid, &.is-valid, .was-validated &:valid + label, &.is-valid + label {
      color: $primary;
    }
    .was-validated &:invalid, &.is-invalid, .was-validated &:invalid + label, &.is-invalid + label {
      color: $danger;
    }
  }
  .invalid-feedback {
    position: absolute;
    top: 0;
    right: 0;
    width: 50%;
    margin-top: 0;
    font-style: italic;
    text-align: right;
  }
}

.custom-checkbox .custom-control-label {
  min-height: 24px;
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-left: 12px;
  font-size: 16px;
}

.custom-checkbox .custom-control-label::after, .custom-checkbox .custom-control-label::before {
  border: 1px solid $secondary;
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background: $primary;
  border-color: $primary;
} 

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  border-color: $primary;
}

select.form-control {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' x='0px' y='0px' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000' xml:space='preserve'%3E%3Cmetadata%3E Svg Vector Icons : http://www.onlinewebfonts.com/icon %3C/metadata%3E%3Cg%3E%3Cpath d='M500,10'/%3E%3Cpath d='M500,990L108,598h784L500,990z'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 8px;
  background-repeat: no-repeat;
  background-position: 100%;
}