/*  Goobank Theme
 *  Built on top of Bootstrap 4 (https://getbootstrap.com)
 *  Built with the Bootstrap Theme Kit by HackerThemes (https://hackerthemes.com)
 */
@import url('https://fonts.googleapis.com/css?family=Kanit:300,400,500,600,700&subset=thai');

@import 'custom-variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'custom-styles';

